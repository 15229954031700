.whoweare-main {

  .experience-section {
    padding: 2rem 0;
    background-image: url("../assets/images/VSO1.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $primary;

    h2 {
      line-height: 1.5rem;
      text-transform: uppercase;
      color: $white;
      font-family: graphik-semibold;
    }
    p{
      color: $white;
    }
    .custom-btn{
      color: $white;
      &:hover{
        color: $white !important;
      }
    }

    .image-section {
      img{
        width:auto;
        height: 800px;
      }
      .image-container {
        position: relative;
        margin-top: 2rem;

        width: fit-content;

        .border-white-img {
          position: absolute;
          height: 110px;
          width: 122px;
          border-radius: 100px;
          border: 2px solid #fff;
          padding: 10px;
          top: 5px;
          bottom: 5px;
          right: -18px;
          z-index: 1;
          .bg-white-img {
            width: 100%;
            height: 100%;
            border-radius: 100px;
            background-color: $white;
          }

          &.right-radius {
            right: -15px;
          }

          &.left-radius {
            left: -15px;
          }
        }

        img {
          position: relative;
          height: 120px;
          width: 122px;
          z-index:100;
        }
      }
    }
  }

  .whoweare-section {
    position: relative;

    .right-top-img {
      height: 1300px;
      position: absolute;
      right: 0;
      top: -20%;
      z-index: -1;
    }

    .left-top-img {
      height: 500px;
      position: absolute;
      left: 0;
      top: -20%;
      z-index: -1;

    }

    .left-bottom-img {
      height: 1000px;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
    }

    .online-platform {
      margin-top: 10rem;

      h2 {
        font-family: graphik-semibold;
        color: $primary;
        font-size: 1.8rem;
      }
      h6 {
        font-size: .9rem;
      }
      b {
        color: $grey;
        font-family: graphik-bold;
        font-size: 0.9rem;
      }

      p {
        font-size: .8rem;
        color: $dark-grey;
      }
    }

    .mobile-platform {
      height: 100%;
      z-index: 100;
    }

    .solution {
      margin-top: 10rem;

      h2 {
        font-family: graphik-semibold;
        font-size: 2.5rem;
        color: $primary;
        text-align: center;
        text-transform: uppercase;
      }

      .btn-main {
        margin-top: 7rem;

        .custom-btn {
          margin-right: 0 !important;
        }
      }

      .solution-image-container {
        .vso-img {
          position: relative;
          background-image: url("../assets/images/VSO.png");
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          width: 550px;
          height: 550px;
          .tooltip > .tooltip-inner{
            background-color: $white !important;
          }
          .importer-img {
            width: 150px;
            position: absolute;
            right: 5%;
            top: 100px;
            cursor: help;
            transition: all .5s ease-out;
                &:hover {
                    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.185));
                    transition: all .5s ease-out;
                }
          }

          .producer-img {
            width: 150px;

            position: absolute;
            left: 5%;
            top: 100px;
            cursor: help;
            transition: all .5s ease-out;
                &:hover {
                    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.185));
                    transition: all .5s ease-out;
                }
          }

          .consumer-img {
            width: 150px;
            position: absolute;
            bottom: 0%;
            left: 50%;
            transform: translateX(-50%);
            cursor: help;
            transition: all .5s ease-out;
                &:hover {
                    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.185));
                    transition: all .5s ease-out;
                }
          }

          .investor-img {
            width: 150px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: help;
            transition: all .5s ease-out;
                &:hover {
                    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.185));
                    transition: all .5s ease-out;
                }
          }
        }
      }
    }

    .ourfriends {
      margin-top: 7rem;
      margin-bottom: 7rem;

      h2 {
        font-family: graphik-semibold;
        font-size: 2.5rem !important;
        color: $primary;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 1rem;
      }

      img {
        filter: grayscale(100%);
      }
    }
    .generate-smart{
      .card{
        border-radius: 7px;
        .img-div{
          padding: 10px;
          img{
            height: 150px;
            border-radius: 10px !important;
          }
        }
        .card-body{
          padding: 5px;
          .card-link{
            font-family: graphik-semibold;
            text-decoration: none;
           font-size: 0.6rem !important;

          }
        }
        .card-title{
          font-family: graphik-bold;
          font-size: 0.8rem !important;
          margin: 0;
          margin-bottom: 5px;
        }
        .card-text{
          font-size: 0.6rem !important;
          padding: 0;
          margin: 0;
          margin-bottom: 5px;
          color: #a9a9a9;
        }
      }
      .smart-card{
        padding: 1rem;
        .card-header{
          padding-left: 0;
          padding-right: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: transparent;
          i{
            font-size: 1.2rem;
          }
        }
        .card-body{
          .select-section{
            border: 1px solid $light-grey;
            border-radius: 10px;
            padding: 1rem;
          }
          .card-label{
            font-size: 0.6rem !important;
            color: black;
            font-family: graphik-medium;
            padding: 0;
            margin: 0;
            margin-bottom: 5px;
          }
        }
        .smart-btn{
          margin-top: 10px;
          background-color: $primary;
          height: 30px;
          width: 100px;
          text-align: center;
          border-radius: 7px;
          border: none;
          color: $white;
          font-size: .6rem !important;

        }
        .smart-right-section{
          border-left: 1px solid $light-grey;
          padding: 0 1rem;
          height: 100%;
          .question{
              font-size: 0.6rem !important;
          }
          i{
            color: $green !important;
            font-size: .9rem;
          }
        }
      }
    }
  }
  .overview-main{
    margin-bottom: 3rem;
    .overview-card{
      box-shadow: 0px 0px 20px 5px #c5c5c554;
      border-radius: 5px;
      padding: 1.8rem;
      h6{
        letter-spacing: -1px;
        font-family: graphik-bold;
        font-size: .8rem !important;
      }
      p{
        height: 130px;
        font-size: .8rem !important;
      }
    }
  }
  .closer-card{
    height: 300px;
    width: 100%;
    background-repeat: no-repeat;
    // background-size: cover;
    background-position: center center;
    p{
      color: $white !important;    
      font-size: 2rem !important;  
      font-family: graphik-semibold;
      letter-spacing: -1px;
      text-decoration: none !important;

    }
  }
}