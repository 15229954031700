.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &.line-1 {
    -webkit-line-clamp: 1 !important;
  }

  &.line-2 {
    -webkit-line-clamp: 2 !important;
  }

  &.line-3 {
    -webkit-line-clamp: 3 !important;
  }

  &.line-4 {
    -webkit-line-clamp: 4 !important;
  }

  &.line-5 {
    -webkit-line-clamp: 5 !important;
  }
}

.b-bottom-left-radius{
  border-bottom-left-radius: 0 !important;
}
.b-bottom-right-radius{
  border-bottom-right-radius: 0 !important;
}
.b-top-left-radius{
  border-top-left-radius: 0 !important;
}
.b-top-right-radius{
  border-top-right-radius: 0 !important;
}
.custom-container {
  margin: 0 12rem;
}

.bg-light-green {
  background-color: $light-green;
}

.vh-50 {
  height: 50vh !important;
}
.vh-60 {
  height: 60vh !important;
}
.vh-80 {
  height: 80vh !important;
}

.a{
  text-decoration: none !important;
}