@import '../../scss//fontface';

.sticky-contaniner{
    overflow: hidden;

  .section {
    position: relative;
    height: 70vh;
    background-image: linear-gradient(160deg, #f47c66 0%, #e51906 100%);
  }

  .sticky {
    position: absolute;
    height: 100vh;
    width: 100%;
    background: url(../../assets/images/Parallax1.png);
    background-size: cover;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    &.animation,
    .animation2 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .heading {
      // position: absolute;
      height: 100%;
      width: 100%;

      .overlay {
        font-size: 40px;
        width: 35%;
        position: absolute;
        color:white;
        top: 50%;
        transform: translate(20%, -50%);
        right: 20%;
        margin: 0;
        h2{
          font-family: graphik-semibold;
        }
        p{
          font-family: graphik-regular;
        }
      }
      
    }
  }
  .sticky2 {
    position: absolute;
    height: 100vh;
    width: 100%;
    background: url(../../assets/images/Parallax2.png);
    background-size: cover;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    &.animation,
    .animation2 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .heading {
      // position: absolute;
      height: 100%;
      width: 100%;

      .overlay {
        font-size: 40px;
        width: 35%;
        position: absolute;
        color:white;
        top: 50%;
        transform: translate(20%, -50%);
        right: 20%;
        margin: 0;
        h2{
          font-family: graphik-semibold;
        }
        p{
          font-family: graphik-regular;
        }
      }
    }
  }
  .sticky3 {
    position: absolute;
    height: 100vh;
    width: 100%;
    background: url(../../assets/images/Parallax3.png);
    background-size: cover;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    &.animation,
    .animation2 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .heading {
      // position: absolute;
      height: 100%;
      width: 100%;
      // top: 0;
      // right: 0;
      // bottom: 0;
      // left: 0;
    
      // margin: auto;

      .overlay {
        font-size: 40px;
        width: 35%;
        position: absolute;
        color:white;
        top: 50%;
        transform: translate(20%, -50%);
        right: 20%;
        margin: 0;
        h2{
          font-family: graphik-semibold;
        }
        p{
          font-family: graphik-regular;
        }
      }
    }
  }
}