@font-face {
    font-family: graphik-thin;
    src: url(../assets/fonts/GraphikThin.otf);
}

@font-face {
    font-family: graphik-super;
    src: url(../assets/fonts/GraphikSuper.otf);
}

@font-face {
    font-family: graphik-semibold;
    src: url(../assets/fonts/GraphikSemibold.otf);
}

@font-face {
  font-family: graphik-regular;
  src: url(../assets/fonts/GraphikRegular.otf);
}
@font-face {
    font-family: graphik-medium;
    src: url(../assets/fonts/GraphikMedium.otf);
}
@font-face {
    font-family: graphik-light;
    src: url(../assets/fonts/GraphikLight.otf);
}
@font-face {
    font-family: graphik-bold;
    src: url(../assets/fonts/GraphikBold.otf);
}
@font-face {
    font-family: graphik-black;
    src: url(../assets/fonts/GraphikBlack.otf);
}