$primary: #b1da50;
$light-green: #e8f4d9;
$primary-gradient: linear-gradient(to bottom, #69832c, #7a9834, #8cad3d, #9ec346, #b1d94f);
$primary-gradient-r-l: linear-gradient(to left, #69832c, #7a9834, #8cad3d, #9ec346, #b1d94f);
$blue:#005582;
$red: #b01116;
$light-grey:#c5c5c5;
$black : #000;
$white : #fff;
$dark-grey: #7c7c7c;
$grey: #666666;
$green:#4bae4f;
$dividen-grey: hsl(0, 0%, 84%);