// .custom-btn{
//     height: 40px;
//     border-radius: 5px !important;
//     background-color: transparent;
//     padding: 0 20px;
//     margin-right: 2rem;
//     border: 2px solid;
//     border-image-slice: 1;
//     font-size: 16px;
//     &.border-gradient-green {
//         border-image-source: $primary-gradient-r-l
//       }
//     &.border-gradient-purple {
//         border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
//       }  

// }
.custom-btn {
  height: 55px;
  border-radius: 5px !important;
  background-color: transparent;
  padding: 0 40px;
  margin-right: 2rem;
  border: 2px solid transparent;
  // border-image-slice: 1;
  // font-size: 15px;
  font-size: 1.2rem;
  color: black;
  font-weight: bold;
  transition: all .5s ease-out;
  &.border-gradient-green {
    position: relative;
    &.filter-drop-shadow{
      filter: drop-shadow(2px 0px 5px black);
    }
    
    &::before{
      content:"";
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      border-radius:5px; 
      padding:2px; 
      background: linear-gradient(270deg, #69832c, #7a9834, #8cad3d, #9ec346, #b1d94f);
      -webkit-mask: 
         linear-gradient(#fff 0 0) content-box, 
         linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
              mask-composite: exclude; 
    }
  }

  &.border-gradient-bg-white {
    position: relative;
    background-color: white !important;
    &.filter-drop-shadow{
      filter: drop-shadow(2px 0px 5px black);
    }
    &::before{
      content:"";
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      border-radius:5px; 
      padding:2px; 
      background: linear-gradient(270deg, #69832c, #7a9834, #8cad3d, #9ec346, #b1d94f);
      -webkit-mask: 
         linear-gradient(#fff 0 0) content-box, 
         linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
              mask-composite: exclude; 
    }
  }
  &:hover{
    background: $primary; 
    transition: all .5s ease-out;
    color: black !important;
    &.border-gradient-green {
      filter: drop-shadow(2px 0px 5px rgba(0, 0, 0, 0.411));
      &::before{
        padding:0px !important; 
      }
    }
  }

}