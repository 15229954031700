.tooltip {
    .tooltip-arrow {
        position: absolute;
        display: block;
        width: 0.8rem;
        height: 0.8rem;

        &::before {
            position: absolute;
            content: "";
            border-color: #fff !important;
            border-style: solid;
        }
    }

    .bs-tooltip-top,
    .bs-tooltip-auto[data-popper-placement^=top] {
        padding: 0.4rem 0;
    }

    .bs-tooltip-top .tooltip-arrow,
    .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
        bottom: 0;
    }

    .bs-tooltip-top .tooltip-arrow::before,
    .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
        top: -1px;
        border-width: 0.4rem 0.4rem 0;
        border-top-color: #fff;
        box-shadow: 1px 1px 10px 5px rgba(0, 0, 0, 0.185);
    }

    .tooltip-inner {
        padding: 1rem;
        color: #666666;
        text-align: center;
        background-color: #fff;
        border-radius: 1rem;
        box-shadow: 1px 1px 10px 5px rgba(0, 0, 0, 0.185);
        z-index: 1;
    }
}