.home-main {

  .experience-section {
    padding: 2rem 0;
    background-image: url("../assets/images/VSO1.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $primary;

    h2 {
      line-height: 1.5rem;
      text-transform: uppercase;
      color: $white;
      font-family: graphik-semibold;
    }

    p {
      color: $white;
    }

    .custom-btn {
      color: $white;

      &:hover {
        color: $white !important;
      }
    }

    .image-section {
      img {
        width: auto;
        height: 800px;
      }

      .image-container {
        position: relative;
        margin-top: 2rem;

        width: fit-content;

        .border-white-img {
          position: absolute;
          height: 110px;
          width: 122px;
          border-radius: 100px;
          border: 2px solid #fff;
          padding: 10px;
          top: 5px;
          bottom: 5px;
          right: -18px;
          z-index: 1;

          .bg-white-img {
            width: 100%;
            height: 100%;
            border-radius: 100px;
            background-color: $white;
          }

          &.right-radius {
            right: -15px;
          }

          &.left-radius {
            left: -15px;
          }
        }

        img {
          position: relative;
          height: 120px;
          width: 122px;
          z-index: 100;
        }
      }
    }
  }

  .onlineplatform-section {
    position: relative;

    .right-top-img {
      height: 1300px;
      position: absolute;
      right: 0;
      top: -20%;
      z-index: -1;
    }

    .left-top-img {
      height: 500px;
      position: absolute;
      left: 0;
      top: -20%;
      z-index: -1;

    }

    .left-bottom-img {
      height: 1000px;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
    }

    .online-platform {
      margin-top: 10rem;

      h2 {
        font-family: graphik-semibold;
        color: $primary;
        text-transform: uppercase;
        font-size: 2.1rem;
      }

      b {
        color: $grey;
        font-family: graphik-bold;
        font-size: 0.9rem;
      }

      p {
        font-size: 1rem;
        color: $dark-grey;
      }
    }

    .mobile-platform {
      height: 600px;
      z-index: 100;
    }

    .solution {
      margin-top: 10rem;

      h2 {
        font-family: graphik-semibold;
        font-size: 2.5rem;
        color: $primary;
        text-align: center;
        text-transform: uppercase;
      }

      .btn-main {
        margin-top: 7rem;

        .custom-btn {
          margin-right: 0 !important;
        }
      }

      .every-party-main-container {
        width: 100%;
        display: flex;

        .every-party-section {
          width: 45%;

          .every-solution-text {
            text-align: left !important;
            color: #b1da50 !important;
          }

          p {
            font-size: 1rem;
            color: $dark-grey;
          }

          .holding-container {
            position: relative;
            width: 100%;
            height: auto;
            margin-top: 50px;

            .producers {
              width: 120px;
              height: 120px;
              background: #fff;
              border-radius: 50%;
              padding-top: 15px;
              font-size: 12px;
              position: absolute;
              text-align: center;
              cursor: pointer;
              font-weight: bold;
              top: 0;

              img {
                width: 40px; 
                height: 40px;
                margin-bottom: 5px;
                object-fit: contain
              }
            }

            .importers {
              width: 120px;
              height: 120px;
              background: #fff;
              border-radius: 50%;
              padding-top: 15px;
              font-size: 12px;
              position: absolute;
              text-align: center;
              cursor: pointer;
              font-weight: bold;
              top: 0;
              right: 0;
              
              img {
                width: 40px; 
                height: 40px;
                margin-bottom: 5px;
                object-fit: contain
              }
            }

            .consumers {
              width: 120px;
              height: 120px;
              background: #fff;
              border-radius: 50%;
              padding-top: 15px;
              font-size: 12px;
              position: absolute;
              text-align: center;
              cursor: pointer;
              font-weight: bold;
              bottom: 0;

              img {
                width: 40px; 
                height: 40px;
                margin-bottom: 5px;
                object-fit: contain
              }
            }

            .investors {
              width: 120px;
              height: 120px;
              background: #fff;
              border-radius: 50%;
              padding-top: 15px;
              font-size: 12px;
              position: absolute;
              text-align: center;
              cursor: pointer;
              font-weight: bold;
              bottom: 0;
              right: 0;

              img {
                width: 40px; 
                height: 40px;
                margin-bottom: 5px;
                object-fit: contain
              }
            }
          }
        }

        .every-party-content-container {
          width: 50%;
          padding-top: 200px;
          padding-left: 50px;

          .content-box {
            width: 100%;
            border-radius: 5px;
            padding: 10px;
            height: auto;
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: bold;

            .heading-container {
              width: 100%;
              display: flex;

              .icon-container {
                width: 8%
              }

              .heading-text-container {
                width: 92%
              }
            }

            .content-container {
              width: 100%;
              margin-top: 10px;

              p {
                font-size: 1rem;
                color: $dark-grey;
                font-weight: 100;
              }
            }
          }
        }
      }

      .solution-image-container {
        .vso-img {
          position: relative;
          background-image: url("../assets/images/VSO.png");
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          width: 550px;
          height: 550px;

          .tooltip>.tooltip-inner {
            background-color: $white !important;
          }

          .importer-img {
            width: 150px;
            position: absolute;
            right: 5%;
            top: 100px;
            cursor: help;
            transition: all .5s ease-out;

            &:hover {
              filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.185));
              transition: all .5s ease-out;
            }
          }

          .producer-img {
            width: 150px;

            position: absolute;
            left: 5%;
            top: 100px;
            cursor: help;
            transition: all .5s ease-out;

            &:hover {
              filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.185));
              transition: all .5s ease-out;
            }
          }

          .consumer-img {
            width: 150px;
            position: absolute;
            bottom: 0%;
            left: 50%;
            transform: translateX(-50%);
            cursor: help;
            transition: all .5s ease-out;

            &:hover {
              filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.185));
              transition: all .5s ease-out;
            }
          }

          .investor-img {
            width: 150px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: help;
            transition: all .5s ease-out;

            &:hover {
              filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.185));
              transition: all .5s ease-out;
            }
          }
        }
      }
    }

    .ourfriends {
      margin-top: 7rem;
      margin-bottom: 7rem;

      h2 {
        font-family: graphik-semibold;
        font-size: 2.5rem;
        color: $primary;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 1rem;
      }

      img {
        filter: grayscale(100%);
      }
    }
  }
}