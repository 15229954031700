.footer {
    background-image: url("../assets/images/FooterBg.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-top: 1px solid $grey;
    // padding: 3rem 0;

    .footer-top {
        .footer-logo {
            img {
                margin: 36px 0 26px 0;
            }

            p {
                color: $light-grey;
                font-size: 12px;
            }
        }

        color: $white;

        .footer-links {
            h4 {
                font-size: 1rem;
            }

            ul {
                padding-left: 0;

                li {
                    list-style: none;
                    padding-left: 0;

                    a {
                        text-decoration: none;
                        color: $light-grey;
                        font-size: 13px;
                    }

                }
            }
        }

        .applynow {
            height: 60px;
            border: 2px solid $white;
            border-radius: 10px;

            input {
                width: 90%;
                height: 56px;
                border: none;
                background-color: transparent;
                color: $dark-grey;
                padding-left: 20px;
            }

            input:focus {
                border: none;
            }

            img {
                cursor: pointer;
            }
        }
    }


}