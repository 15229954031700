@import "variable";
@import "fontface";
@import 'utilities';
@import 'animation';
@import 'button';
@import 'tooltip';
@import "toast";
@import "header";
@import "home";
@import "whoweare";
@import "timeline";
@import "contactus";
@import "technology";
@import "investor";
@import "footer";
@import 'responsive';

body{
    font-family: graphik-regular !important ;
}
body > iframe{
  z-index: -1 !important;
  display: none !important;
}
.color-white{
  color: $white !important;
}
::-webkit-scrollbar {
    width: 7px;
    height: 7px;         
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $light-green; 
    border-radius: 0px;
    scroll-margin: 2px !important;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $primary; 
    border-radius: 0px;
}
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $red; 
  }


  .navbar-light .navbar-toggler {
    color: transparent;
  }

.loader-lotie{
  width: 100px;
  position: fixed;
  z-index: 10001;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  &:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
      background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));
  
    background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
  }
  
 }