.header-banner {
    position: relative;
    height: 80vh;
    // background-image: url('../assets/images/banner.png');
    // background-size: cover;
    // background-position: center center;
    // background-repeat: no-repeat;
    width: 100%;
    background: linear-gradient(        90deg,        rgba(0, 0.5, 1, 0.8) 25.67%,        rgba(0, 0.5, 1, 0.7) 77.1%      ),url(https://fulfillment.trumarket.tech/_next/static/media/home-banner-cover.363bc130.jpg),#ffffff;
    background-blend-mode: unset;
    background-repeat: no-repeat;
    -webkit-background-position: center;
    background-position: center;
    -webkit-background-size: cover;
    background-size: cover;
   // height: 620px;

    .bg-video-wrap {
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        width: 100%;
        height: 80vh;
        // background: url(https://designsupply-web.com/samplecontent/vender/codepen/20181014.png) no-repeat center center/cover;
    }

    .bg-image-wrap {
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        z-index: 99;
        width: 100%;
        height: 60vh;
        // height: auto;
        // background: url(https://designsupply-web.com/samplecontent/vender/codepen/20181014.png) no-repeat center center/cover;
        img{
            height: 100%;
            width: 100%;
        }
    }

    iframe {
        height: inherit;
        width: inherit;
    }

    video {
        min-width: 100%;
        min-height: 100vh;
    }

    .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: linear-gradient(rgb(0 0 0 / 48%) 100%, rgb(0 0 0 / 50%) 100%);
        background-size: 3px 3px;
        // z-index: 2;
    }

    //   h1 {
    //     text-align: center;
    //     color: #fff;
    //     position: absolute;
    //     top: 0;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //     margin: auto;
    //     z-index: 3;
    //     max-width: 400px;
    //     width: 100%;
    //     height: 50px;
    //   }
    .banner-main {
        z-index: 5;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

        h1 {
            font-family: graphik-semibold;
            color: $white !important;
            font-size: 3.5rem;
            text-transform: uppercase;
            background-color: $primary;
            padding: 0 10px;
            letter-spacing: -3px;

            span {
                border-right: .05em solid;
                animation: caret 1s steps(1) infinite;
            }

            @keyframes caret {
                50% {
                    border-color: transparent;
                }
            }
        }

        h2 {
            margin-top: 100px;
            font-family: graphik-semibold;
            color: $white !important;
            font-size: 2rem;
            text-transform: uppercase;
            letter-spacing: -1px;
        }
    }

    .header {
        height: inherit;

        .navbar {
            z-index: 100;
            height: 60px;
            padding: 0;
            display: flex;
    align-items: baseline;
            .navbar-toggler {
                border-color: transparent;
                background: #fff;
            }
        }

        .navbar-brand {
            height: 100%;
            margin-left: 1.4rem;
            img {
                height: 70px;
            }
            padding-top: 0;
            padding-bottom: 0;
        }
       
        .button-marketplace {
            color: rgba(0, 0, 0, 0.71);
            background: #B0D950;
            border-radius: 10px;
            text-decoration: none;
            padding: 12px 18px;
            font-size: 14px;
            font-weight: 600;
            margin-right: 20px;
            letter-spacing: 1px;
        }

        .custom-nav {
            // background-color: $white;
            z-index: 1;
            height: 100%;

            .navbar-nav {
                height: 85%;

                li {
                    color: $black;
                    .nav-link {
                        margin-right: 16px;
                        margin-left: 16px;
                        // font-size: 16px;
                        // font-weight: lighter;
                        // font-family: Arial, Helvetica, sans-serif;
                        color: #fff;
                        position: relative;
                        &.active{
                            color: #B0D950;
                        }
                        &.active::after{
                            content: "";
                            position: absolute;
                            width: 6px;
                            height: 6px;
                            background: #B0D950;
                            border-radius: 50%;
                            bottom: 0px;
                            left: calc(50% - 3px);
                            
                        }
                    }
                    background-size: 200% 100%;
                    :hover{
                        color: #B0D950;
                        // transition:all 2s ease;
                    }
                }
            }
            
                            

            .fullfilment {
                background: $primary-gradient;
                height: 100%;
                border-bottom-left-radius: 2.5rem;
                color: $white;
                font-size: 1.1rem;
                font-weight: bold;
            }
        }


    }
}