@import url(https://fonts.googleapis.com/css?family=Lato);

$title-background: #3d9e67;
$background-color: #faf8eb;
$content-background-color: #67CC8E;
$content-background-gradient: #56BC83;
$time-color: #fff;
$text-color: #fff;
$content-width: 450px;

// *,
// *::before,
// *::after {
//     margin: 0;
//     padding: 0;
//     -webkit-box-sizing: border-box;
//     -moz-box-sizing: border-box;
//     box-sizing: border-box;
// }

.timeline {
    color: $text-color;

    h1,
    ul li .content h2 {
        text-shadow: 1px 1px 1px rgba(56, 56, 56, 0.5);
    }

    h1 {
        background: $title-background;
        padding: 70px 0;
        font-size: 2.5em;
        text-align: center;
    }

    ul {
        background: $background-color;
        padding: 50px 0;

        li {
            background: $content-background-color;
            position: relative;
            margin: 0 auto;
            width: 5px;
            padding-bottom: 40px;
            list-style-type: none;

            &:last-child {
                padding-bottom: 7px;
            }

            &:before {
                content: '';
                background: $background-color;
                position: absolute;
                left: 50%;
                top: 0;
                transform: translateX(-50%);
                -webkit-transform: translateX(-50%);
                width: 20px;
                height: 20px;
                border: 3px solid $content-background-color;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
            }

            .hidden {
                opacity: 0;
            }

            .content {
                background: $content-background-color;
                position: relative;
                top: 7px;
                width: $content-width;
                padding: 20px;

                h2 {
                    color: $time-color;
                    padding-bottom: 10px;
                    text-align: center;
                }

                p {
                    text-align: center;
                }

                &:before {
                    content: '';
                    background: $content-background-color;
                    position: absolute;
                    top: 0px;
                    width: 38px;
                    height: 5px;
                }
            }

            &:nth-child(odd) .content {
                left: 50px;
                background: $content-background-color;
                background: -webkit-linear-gradient(-45deg, $content-background-gradient, $content-background-color);
                background: linear-gradient(-45deg, $content-background-gradient, $content-background-color);
            }

            &:nth-child(odd) .content:before {
                left: -38px;
            }

            &:nth-child(even) .content {
                left: calc(-#{$content-width} - 45px);
                background: $content-background-color;
                background: -webkit-linear-gradient(45deg, $content-background-gradient, $content-background-color);
                background: linear-gradient(45deg, $content-background-gradient, $content-background-color);
            }

            &:nth-child(even) .content:before {
                right: -38px;
            }
        }
    }
}

/* -------------------------
   ----- Media Queries ----- 
   ------------------------- */

@media screen and (max-width: 1020px) {
    .timeline ul li .content {
        width: 41vw;
    }

    .timeline ul li:nth-child(even) .content {
        left: calc(-41vw - 45px);
    }
}

@media screen and (max-width: 700px) {
    .timeline ul li {
        margin-left: 20px;

        .content {
            width: calc(100vw - 100px);

            h2 {
                text-align: initial;
            }
        }

        &:nth-child(even) .content {
            left: 45px;
            background: $content-background-color;
            background: -webkit-linear-gradient(-45deg, $content-background-gradient, $content-background-color);
            background: linear-gradient(-45deg, $content-background-gradient, $content-background-color);
        }

        &:nth-child(even) .content:before {
            left: -33px;
        }
    }
}


.text_1 {
    animation: text1;
}

.text_2 {
    animation: text2;
}

.text_1,
.text_2 {
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    position: relative;
    animation-duration: 20s;
    animation-timing-function: steps(25, end);
    animation-iteration-count: infinite;
}

.text_1::after,
.text_2::after {
    content: "|";
    position: absolute;
    right: 0;
    animation: caret infinite;
    animation-duration: 1s;
    animation-timing-function: steps(1, end);
}

@keyframes text2 {

    0%,
    50%,
    100% {
        width: 0;
    }

    60%,
    90% {
        width: 21.2em;
    }
}

@keyframes text1 {

    0%,
    50%,
    100% {
        width: 0;
    }

    10%,
    40% {
        width: 17em;
    }
}

@keyframes caret {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}


