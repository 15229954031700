@media (min-width: 1501px) {
    .contactus .info-details {
        padding-left: 18rem;
    }

    .header .custom-nav {
        height: 100%;
    }

    .home-main .onlineplatform-section .mobile-platform {
        height: 500px;
    }

    .sticky-contaniner .sticky .heading .overlay {
        width: 45% !important;
        right: 15% !important;
    }

    .sticky-contaniner .sticky2 .heading .overlay {
        right: 15% !important;
        width: 45% !important;
    }

    .sticky-contaniner .sticky3 .heading .overlay {
        right: 15% !important;
        width: 45% !important;
    }

    .overlay h2 {
        font-size: 2.5rem !important;
    }

    .overlay p {
        font-size: 1.0rem !important;
    }

    h2 {
        font-size: 2rem !important;
    }

    p {
        font-size: 1.0rem !important;
    }

    .footer .footer-top .footer-logo p {
        font-size: 12px !important;
    }
}

@media (max-width: 1500px) and (min-width: 1201px) {
    .custom-container {
        margin: 0 12rem;
    }

    .contactus .info-details {
        padding-left: 12rem;
    }

    .overlay h2 {
        font-size: 2rem !important;
    }

    .overlay p {
        font-size: 1rem !important;
    }

    h2 {
        font-size: 1.5rem !important;
    }

    p {
        font-size: 0.8rem !important;
    }

    h3 {
        font-size: 1.2rem !important;
    }

    .contactus .contactus-form form input {
        margin: 10px 0;
        padding-left: 10px;
        height: 40px;
        font-size: 12px;
        border-radius: 5px;

    }

    textarea {
        margin: 10px 0 !important;
        padding-left: 10px !important;
        border-radius: 5px !important;
        font-size: 12px !important;
        height: 200px !important;
    }

    .contactus .contactus-form form select {
        margin: 10px 0;
        padding-left: 7px;
        border-radius: 5px;
        height: 40px;
        font-size: 12px;
    }

    .home-main .onlineplatform-section .right-top-img {
        height: 900px;
        top: -10%;
    }

    .header-banner {
        height: 80vh;
    }

    .header-banner .header .navbar {
        height: 80px;
    }

    .header-banner .banner-main h1 {
        font-size: 2.5rem !important;
    }

    .header-banner .banner-main h2 {
        font-size: 1.5rem !important;
    }

    .header-banner .bg-video-wrap .custom-btn {
        margin: 0 !important;
    }

    .custom-btn {
        height: 50px;
        font-size: 16px;
    }

    .header-banner .header .custom-nav .navbar-nav li .nav-link {
        font-size: 16px;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }

    .header-banner .header .banner-main h2 {
        font-size: 1.6rem;
    }

    .header-banner .header .banner-main h1 {
        font-size: 2.5rem;
    }

    .home-main .onlineplatform-section .solution h2 {
        font-size: 2.2rem !important;
    }

    .home-main .onlineplatform-section .ourfriends h2 {
        font-size: 2.2rem;
    }

    .home-main .onlineplatform-section .mobile-platform {
        height: 500px;
        z-index: 100;
    }

    .header-banner .header .navbar {
        height: 80px;
    }

    .contactus .info-details {
        padding-left: 17rem;
    }

        .every-party-main-container .every-party-section .holding-container {
            .producers,
            .investors,
            .consumers,
            .importers {
                padding-top: 20px !important;
            }
        }
}

@media (max-width: 1200px) and (min-width: 993px) {
    h2 {
        font-size: 1.8rem !important;
    }

    p {
        font-size: 0.9rem !important;
    }

    .sticky-contaniner .sticky .heading .overlay {
        width: 50% !important;
        right: 10% !important;

    }

    .sticky-contaniner .sticky2 .heading .overlay {
        width: 50% !important;
        right: 10% !important;

    }

    .sticky-contaniner .sticky3 .heading .overlay {
        width: 50% !important;
        right: 10% !important;
    }

    .custom-container {
        margin: 0 8rem;
    }

    .contactus .info-details {
        padding-left: 13rem;
    }

    .home-main .onlineplatform-section .right-top-img {
        height: 900px;
        top: -10%;
    }

    .header-banner {
        height: 80vh;
    }

    .header-banner .banner-main h2 {
        font-size: 1.25rem !important;
    }

    .header-banner .banner-main h1 {
        font-size: 2rem !important;
    }

    .header-banner .header .navbar {
        height: 70px;
    }

    .header-banner .header .custom-nav .fullfilment {
        border-bottom-left-radius: 1.5rem;
    }

    .custom-btn {
        font-size: 16px;
    }

    .header-banner .header .custom-nav .navbar-nav li .nav-link {
        font-size: 16px;
        padding-right: 1.2rem;
        padding-left: 1.2rem;
    }

    .header-banner .header .banner-main h2 {
        font-size: 1.6rem;
    }

    .header-banner .header .banner-main h1 {
        font-size: 2.5rem;
    }

    .header-banner .banner-main {
        h2 {
            font-size: 1.6rem;
        }
    }

    .header-banner .bg-image-wrap {
        height: 50vh;
    }

    .home-main .onlineplatform-section .solution h2 {
        font-size: 2.2rem;
    }

    .home-main .onlineplatform-section .ourfriends h2 {
        font-size: 2.2rem;
    }

    .home-main .onlineplatform-section .mobile-platform {
        height: 400px;
        z-index: 100;
    }

    .contactus h2 {
        font-size: 1.4rem !important;
    }

    .contactus h3 {
        font-size: 1rem !important;
    }

    .contactus .info-details h3 i {
        font-size: 1rem;
    }

    .contactus .socail-icons i {
        font-size: 1rem !important;
    }

    .contactus p {
        font-size: 14px !important;
    }

    .contactus .contactus-form form input {
        margin: 8px 0;
        padding-left: 14px;
        border-radius: 5px;
        height: 30px;
        font-size: 11px;
    }

    .contactus .contactus-form form textarea {
        margin: 8px 0;
        padding-left: 14px;
        padding-top: 10px;
        border-radius: 5px;
        // height: 250px;
        height: auto;
        font-size: 11px;
    }

    .contactus .contactus-form form select {
        margin: 8px 0;
        padding-left: 10px;
        border-radius: 5px;
        height: 30px;
        font-size: 11px;
    }

    .custom-btn {
        height: 30px;
        padding: 0 20px;
        font-size: 16px;
        margin-right: 30px;
    }

    .custom-btn.border-gradient-green::before {
        border-radius: 3px;
        padding: 1px;
    }

    .contactus .online-platform {
        margin-top: 10rem !important;
    }

    .foodchain h2 {
        font-size: 2.5rem;
    }

    .network {
        font-size: 1rem;
        background-color: black;
    }

    .values-section .foodchain h2 {
        font-size: 2rem;
    }

    .trades-section {
        padding-top: 0rem !important;
    }

    .values-section .solution-image-container .vso-img {
        width: 350px;
        height: 350px;
    }

    .values-section .solution-image-container .vso-img .importer-img {
        width: 150px;
        right: -20%;
    }

    .values-section .solution-image-container .vso-img .producer-img {
        width: 150px;
        left: -25%;
    }

    .values-section .solution-image-container .vso-img .consumer-img {
        width: 150px;
        left: 35%;
    }

    .values-section .foodchain p {
        text-align: center;
    }

    .home-main .onlineplatform-section .online-platform {
        margin-top: 5rem;
    }

    .footer .footer-top .footer-links ul li a {
        font-size: 10px !important;
    }

    .invest-now .invest-form h1 {
        font-family: graphik-semibold;
        color: #fff;
        font-size: 1.5rem;
    }

    .invest-now .invest-form p {
        font-size: .7rem;
    }

    .invest-now .invest-form form input {
        border: 1px solid #fff;
        border-radius: 4px;
        height: 30px;
        font-size: 0.7rem;
    }

    .invest-now .invest-form form textarea {
        padding-top: 10px;
        border-radius: 4px;
        border: 1px solid #fff;
        width: 100%;
        font-size: 0.7px !important;
        height: 70px !important;
        background-color: transparent;
    }

    .supplier-main p {
        font-size: 0.7rem;
    }

    .supplier-main h1 {
        font-size: 1.4rem;

    }

    .supplier-main button {
        width: 250px;
        font-size: .9rem !important;
    }

    .whoweare-main .whoweare-section .solution h2 {
        font-size: 1.8rem;
    }

    .whoweare-main .whoweare-section .online-platform {
        margin-top: 5rem;
    }

    .whoweare-main .whoweare-section .solution {
        margin-top: 5rem;
    }

    .invest-now .invest-form {
        padding-left: 8rem !important;
    }

    .every-party-main-container .every-party-section .holding-container {    
        .producers,
        .investors,
        .consumers,
        .importers {
            padding-top: 20px !important;
        }
    }
}

@media (max-width: 992px) and (min-width: 769px) {
    .sticky-contaniner .sticky .heading .overlay {
        width: 60% !important;
    }

    .sticky-contaniner .sticky2 .heading .overlay {
        width: 60% !important;
    }

    .sticky-contaniner .sticky3 .heading .overlay {
        width: 60% !important;
    }

    h2 {
        font-size: 1.5rem !important;
    }

    p {
        font-size: .9rem !important;
    }

    .custom-container {
        margin: 0 6rem;
    }

    .contactus .info-details {
        padding-left: 6rem;
    }

    .custom-nav {
        height: fit-content !important;
        background: #000;
    }

    .home-main .onlineplatform-section .right-top-img {
        height: 800px;
        width: 500px;
        top: -10%;
    }

    .home-main .onlineplatform-section .left-bottom-img {
        height: 800px;
    }

    .header-banner {
        height: 80vh;
    }

    .header-banner .banner-main h2 {
        font-size: 1.25rem !important;
    }

    .header-banner .banner-main h1 {
        font-size: 2rem !important;
    }

    .header-banner .header .navbar {
        height: 60px;
        padding: 0 15px;
    }

    .header-banner .bg-image-wrap {
        height: 50vh;
    }

    .custom-btn {
        font-size: 16px;
    }

    .header-banner .header .custom-nav .navbar-nav li .nav-link {
        font-size: 16px;
        padding-right: 1.2rem;
        padding-left: 1.2rem;
    }

    .header-banner .header .custom-nav .fullfilment {
        margin-top: 5px;
        border-bottom-left-radius: 0rem;
    }

    .header-banner .header .banner-main h2 {
        font-size: 1.6rem;
    }

    .header-banner .header .banner-main h1 {
        font-size: 2.5rem;
    }

    .home-main .onlineplatform-section .solution h2 {
        font-size: 2.2rem;
    }

    .home-main .onlineplatform-section .ourfriends h2 {
        font-size: 2.2rem;
    }

    .home-main .onlineplatform-section .mobile-platform {
        height: 400px;
        z-index: 100;
    }

    .home-main .onlineplatform-section .solution .btn-main {
        margin-top: 4rem;
    }

    .home-main .onlineplatform-section .online-platform {
        margin-top: 5rem;
    }

    .home-main .onlineplatform-section .solution {
        margin-top: 5rem;
    }

    .home-main .onlineplatform-section .ourfriends {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }

    .contactus h2 {
        font-size: 1.2rem !important;
    }

    .contactus h3 {
        font-size: 0.8rem !important;
    }

    .contactus .info-details h3 i {
        font-size: 1rem;
    }

    .contactus .socail-icons i {
        font-size: 1rem !important;
    }

    .contactus p {
        font-size: 12px !important;
    }

    .contactus .contactus-form form input {
        margin: 12px 0;
        padding-left: 12px;
        border-radius: 5px;
        height: 40px;
        font-size: 12px;
    }

    .contactus .contactus-form form select {
        margin: 12px 0;
        padding-left: 8px;
        border-radius: 5px;
        height: 40px;
        font-size: 12px;
    }

    .contactus .contactus-form form textarea {
        margin: 12px 0;
        padding-left: 12px;
        padding-top: 10px;
        border-radius: 5px;
        // height: 200px;
        height: auto;
        font-size: 12px;
    }

    .contactus .contactus-form {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }

    .contactus .contactus-form form {
        border-radius: 10px;
    }

    .home-main .onlineplatform-section .solution .solution-image-container .vso-img {
        width: 450px;
        height: 450px;
    }

    .home-main .onlineplatform-section .solution .solution-image-container .vso-img .producer-img {
        width: 120px;
        left: 5%;
    }

    .home-main .onlineplatform-section .solution .solution-image-container .vso-img .consumer-img {
        width: 120px;
        bottom: 0%;
    }

    .home-main .onlineplatform-section .solution .solution-image-container .vso-img .investor-img {
        width: 120px;
    }

    .home-main .onlineplatform-section .solution .solution-image-container .vso-img .importer-img {
        width: 120px;
        right: 5%;
    }

    .foodchain h2 {
        font-size: 2.5rem;
    }

    .network {
        font-size: 1rem;
        background-color: black;
    }

    .values-section .foodchain h2 {
        font-size: 2rem;
    }

    .trades-section {
        padding-top: 0rem !important;
    }

    .values-section .solution-image-container .vso-img {
        width: 450px;
        height: 450px;
    }

    .values-section .solution-image-container .vso-img .importer-img {
        width: 120px;
        right: 5%;
    }

    .values-section .solution-image-container .vso-img .producer-img {
        width: 120px;
        left: 5%;
    }

    .values-section .solution-image-container .vso-img .consumer-img {
        width: 120px;
        bottom: 0%;
    }

    .values-section .foodchain p {
        text-align: center;
    }

    .whoweare-main .whoweare-section .solution h2 {
        font-size: 1.8rem;
    }

    .custom-btn {
        height: 40px;
        padding: 0 20px;
        font-size: 16px;
        margin-right: 0px;
    }

    .invest-now .invest-form {
        padding-left: 5.5rem !important;
    }

    .whoweare-main .whoweare-section .online-platform {
        margin-top: 5rem;
    }
}

@media (max-width: 768px) and (min-width: 577px) {
    .sticky-contaniner .sticky .heading .overlay {
        width: 60% !important;
    }

    .sticky-contaniner .sticky2 .heading .overlay {
        width: 60% !important;
    }

    .sticky-contaniner .sticky3 .heading .overlay {
        width: 60% !important;
    }

    .overlay h2 {
        font-size: 2rem !important;
    }

    .overlay p {
        font-size: 1rem !important;
    }

    .custom-container {
        margin: 0 3rem;
    }

    .contactus .info-details {
        padding-left: 2rem;
    }

    .header-banner .header .navbar {
        height: 60px;
        padding: 0 20px;
    }

    .header-banner .header .custom-nav .navbar-nav li .nav-link {
        font-size: 0.7rem;
    }

    .custom-nav {
        height: fit-content !important;
        background: #000;
    }

    .home-main .onlineplatform-section .mobile-platform {
        height: 350px;
    }

    .home-main .onlineplatform-section .solution h2 {
        font-size: 1.5rem;
    }

    .home-main .onlineplatform-section .solution .solution-image-container .vso-img {
        width: 450px;
        height: 450px;
    }

    .home-main .onlineplatform-section .solution .solution-image-container .vso-img .producer-img {
        width: 120px;
        left: 5%;
    }

    .home-main .onlineplatform-section .solution .solution-image-container .vso-img .consumer-img {
        width: 120px;
        bottom: 0%;
    }

    .home-main .onlineplatform-section .solution .solution-image-container .vso-img .investor-img {
        width: 120px;
    }

    .home-main .onlineplatform-section .solution .solution-image-container .vso-img .importer-img {
        width: 120px;
        right: 5%;
    }

    .home-main .onlineplatform-section .online-platform {
        margin-top: 3rem;
    }

    .home-main .onlineplatform-section .right-top-img {
        height: 500px;
        top: 0%;
    }

    .home-main .onlineplatform-section .left-top-img {
        height: 300px;
        top: -13%;
    }

    .home-main .onlineplatform-section .left-bottom-img {
        height: 400px;
    }

    .contactus .left-top-img {
        height: 400px !important;
    }

    .header-banner .banner-main h2 {
        font-size: 1.5rem;
        margin-top: 30px;
    }

    .header-banner .banner-main h1 {
        font-size: 2rem;
    }

    .custom-btn {
        height: 40px;
        padding: 0 20px;
        font-size: 10px;
        margin-right: 0px;
    }

    .custom-btn.border-gradient-green::before {
        border-radius: 3px;
        padding: 1px;
    }

    .header-banner .banner-main {
        bottom: -40px;
    }

    .header-banner {
        height: 40vh !important;
    }

    .header-banner .bg-video-wrap {
        height: 40vh;
    }

    .header-banner .bg-image-wrap {
        height: 40vh;
    }

    .trades-section {
        position: relative;
        padding: 2rem 0;
    }

    .technology .onlineplatform-section .online-platform {
        margin-top: 0rem;
    }

    .network {
        padding: 2rem 0;
        background: $black;

    }

    .ourmission-section {
        padding: 2rem 0;
    }

    .technology h2 {
        font-size: 1.2rem;
    }

    .technology p {
        font-size: 12px;
    }

    .contactus h2 {
        font-size: 1.2rem !important;
    }

    .contactus h3 {
        font-size: 0.8rem !important;
    }

    .contactus .info-details h3 i {
        font-size: 1rem;
    }

    .contactus .socail-icons i {
        font-size: 1rem !important;
    }

    .contactus p {
        font-size: 12px !important;
    }

    .contactus .contactus-form form input {
        margin: 8px 0;
        padding-left: 12px;
        border-radius: 5px;
        height: 40px;
        font-size: 12px;
    }

    .contactus .contactus-form form select {
        margin: 8px 0;
        padding-left: 8px;
        border-radius: 5px;
        height: 40px;
        font-size: 12px;
    }

    .contactus .contactus-form form textarea {
        margin: 8px 0;
        padding-left: 12px;
        padding-top: 10px;
        border-radius: 5px;
        // height: 200px;
        height: auto;
        font-size: 12px;
    }

    .contactus .contactus-form {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }

    .contactus .contactus-form form {
        border-radius: 10px;
    }

    .technology .values {
        margin: 2rem 0 !important;
    }

    .technology .values .solution-image-container .vso-img {
        width: 450px;
        height: 450px;
    }

    .technology .values .solution-image-container .vso-img .producer-img {
        width: 120px;
    }

    .technology .values .solution-image-container .vso-img .consumer-img {
        width: 120px;
    }

    .technology .values .solution-image-container .vso-img .importer-img {
        width: 120px;
    }

    .header-banner .header .custom-nav .fullfilment {
        margin-top: 5px;
        border-bottom-left-radius: 0rem;
    }

    .technology .foodchain h2 {
        font-size: 1.5rem;
    }

    .technology .network {
        font-size: 1rem;
        background-color: black;
    }

    .technology .values-section .foodchain h2 {
        font-size: 1.5rem;
    }

    .technology .trades-section {
        padding-top: 3rem !important;

        .bg-trades {
            width: 80%;
        }
    }

    .technology .values-section .foodchain p {
        text-align: center;
        font-size: 0.7rem;
    }

    .technology .image-section {
        .dataInt {
            width: 70%;
        }
    }

    .whoweare-main .whoweare-section .solution h2 {
        font-size: 1.5rem;
    }

    .supplier-main button {
        width: 150px;
        font-size: .7rem;
        border-radius: 5px;
    }

    .supplier-main p {
        width: 100%;
        font-size: .7rem;
    }

    .supplier-main h1 {
        font-size: 1.5rem;
    }

    .invest-now .invest-form {
        padding: 0 4rem;
    }

    .invest-now .invest-form p {
        font-size: .7rem;
    }

    .invest-now .invest-form h1 {
        font-size: 1.5rem;
    }

    .whoweare-main .whoweare-section .online-platform {
        margin-top: 2rem;
    }
}

@media (max-width: 576px) and (min-width: 320px) {
    .sticky-contaniner .sticky .heading .overlay {
        width: 60% !important;
    }

    .sticky-contaniner .sticky2 .heading .overlay {
        width: 60% !important;
    }

    .sticky-contaniner .sticky3 .heading .overlay {
        width: 60% !important;
    }

    .overlay h2 {
        font-size: 2rem !important;
    }

    .overlay p {
        font-size: 1rem !important;
    }

    .custom-container {
        margin: 0 2rem;
    }

    .contactus .info-details {
        padding-left: 2rem;
    }

    .custom-nav {
        height: fit-content !important;
        background: #000;
    }

    .header-banner .header .navbar {
        height: 50px;
        padding: 0 0px;
    }

    .header-banner .header .navbar-brand {
        margin-left: .5rem;
    }

    .header-banner .header .custom-nav .navbar-nav li .nav-link {
        font-size: .7rem;
    }

    .header-banner .banner-main h1 {
        font-size: 2rem;
    }

    .header-banner .banner-main h2 {
        margin-top: 30px;
        font-size: 1.2rem;
    }

    .header-banner .banner-main {
        bottom: -35px;
    }

    .custom-btn {
        height: 30px;
        padding: 0 20px;
        font-size: 10px;
        margin-right: 0px;
    }

    .home-main .onlineplatform-section .mobile-platform {
        height: 350px;
    }

    .home-main .onlineplatform-section .solution h2 {
        font-size: 1.5rem;
    }

    .home-main .onlineplatform-section .ourfriends h2 {
        font-size: 1.5rem;
    }

    .home-main .onlineplatform-section .solution .solution-image-container .vso-img {
        width: 270px;
        height: 270px;
    }

    .home-main .onlineplatform-section .solution .solution-image-container .vso-img .producer-img {
        width: 70px;
        left: 5%;
        top: 25%;
    }

    .tooltip .tooltip-inner {
        font-size: 0.6rem;
    }

    .home-main .onlineplatform-section .solution .solution-image-container .vso-img .consumer-img {
        width: 70px;
        bottom: 0%;
    }

    .home-main .onlineplatform-section .solution .solution-image-container .vso-img .investor-img {
        width: 70px;
    }

    .home-main .onlineplatform-section .solution .solution-image-container .vso-img .importer-img {
        width: 70px;
        right: 5%;
        top: 25%;
    }

    .home-main .onlineplatform-section .online-platform h2 {
        font-size: 1.5rem !important;
    }

    .home-main .onlineplatform-section .online-platform p,
    .home-main .onlineplatform-section .online-platform p b {
        font-size: .8rem;
    }

    .home-main .onlineplatform-section .online-platform {
        margin-top: 3rem;
    }

    .home-main .onlineplatform-section .solution {
        margin-top: 3rem;
    }

    .home-main .onlineplatform-section .ourfriends {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    .home-main .onlineplatform-section .right-top-img {
        height: 500px;
        top: 0%;
    }

    .home-main .onlineplatform-section .left-top-img {
        height: 300px;
        top: -13%;
    }

    .home-main .onlineplatform-section .left-bottom-img {
        height: 400px;
    }

    .contactus .left-top-img {
        height: 600px !important;
    }

    .home-main .onlineplatform-section .solution .btn-main {
        margin-top: 3rem;
    }

    .footer .footer-top .footer-logo img {
        width: 150px;
    }

    .custom-btn.border-gradient-green::before {
        border-radius: 3px;
        padding: 1px;
    }

    .header-banner {
        height: 30vh !important;
    }

    .header-banner .bg-video-wrap {
        height: 30vh;
    }

    .header-banner .bg-image-wrap {
        height: 30vh;
    }

    .trades-section {
        padding: 2rem 0 !important;
    }

    .technology .onlineplatform-section .online-platform {
        margin-top: 0rem;
    }

    .technology .network {
        padding: 2rem 0;
        background: $black;

    }

    .technology .ourmission-section {
        padding: 2rem 0;
    }

    .technology h2 {
        font-size: 1.2rem !important;
    }

    .technology p {
        font-size: 12px !important;
    }

    .contactus h2 {
        font-size: 1.2rem !important;
    }

    .contactus h3 {
        font-size: 0.8rem !important;
    }

    .contactus .info-details h3 i {
        font-size: 1rem;
    }

    .contactus .socail-icons i {
        font-size: 1rem !important;
    }

    .contactus p {
        font-size: 12px !important;
    }

    .contactus .contactus-form form input {
        margin: 8px 0;
        padding-left: 12px;
        border-radius: 5px;
        height: 40px;
        font-size: 12px;
    }

    .contactus .contactus-form form select {
        margin: 8px 0;
        padding-left: 12px;
        border-radius: 5px;
        height: 40px;
        font-size: 12px;
    }

    .contactus .contactus-form form select option {
        font-size: 12px;
    }

    .contactus .contactus-form form textarea {
        margin: 8px 0;
        padding-left: 12px;
        padding-top: 10px;
        border-radius: 5px;
        // height: 200px;
        height: auto;
        font-size: 12px;
    }

    .contactus .contactus-form {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }

    .contactus .contactus-form form {
        border-radius: 10px;
    }

    .technology .values {
        margin: 2rem 0 !important;
    }

    .technology .values .solution-image-container .vso-img {
        width: 350px !important;
        height: 350px !important;
    }

    .technology .values .solution-image-container .vso-img .producer-img {
        width: 100px !important;
    }

    .technology .values .solution-image-container .vso-img .consumer-img {
        width: 100px !important;
    }

    .technology .values .solution-image-container .vso-img .importer-img {
        width: 100px !important;
    }

    .header-banner .header .custom-nav .fullfilment {
        margin-top: 5px;
        border-bottom-left-radius: 0rem;
        font-size: .7rem;
        height: 32px;
    }

    .whoweare-main .whoweare-section .online-platform {
        margin-top: 2rem;
    }

    .whoweare-main .whoweare-section .online-platform h2 {
        font-size: 1.5rem;
    }

    .whoweare-main .whoweare-section .online-platform h6 {
        font-size: .8rem;
    }

    .whoweare-main .whoweare-section .online-platform p {
        font-size: .7rem !important;
    }

    .whoweare-main .whoweare-section .generate-smart .card .card-title {
        font-size: .7rem !important;
    }

    .whoweare-main .whoweare-section .generate-smart .smart-card .card-body .select-section {
        border-radius: 5px;
        padding: .8rem;
    }

    .whoweare-main .whoweare-section .generate-smart .card .card-text {
        font-size: .5rem !important;
    }

    .whoweare-main .whoweare-section .generate-smart .smart-card {
        padding: .5rem;
    }

    .whoweare-main .whoweare-section .generate-smart .smart-card .smart-right-section {
        border-left: none;
        margin-bottom: 1rem;
        padding: 0;
    }

    .whoweare-main .closer-card p {
        font-size: 2rem !important;
    }

    .whoweare-main .whoweare-section .solution h2 {
        font-size: 1.5rem;
    }

    .whoweare-main .whoweare-section .solution p {
        font-size: 0.7rem;
    }

    .supplier-main button {
        width: 150px;
        font-size: .7rem;
        border-radius: 5px;
    }

    .supplier-main p {
        width: 100%;
        font-size: .7rem;
    }

    .supplier-main h1 {
        font-size: 1.5rem;
    }

    .invest-now .invest-form {
        padding: 0 1rem;
    }

    .invest-now .invest-form p {
        font-size: .7rem;
    }

    .invest-now .invest-form h1 {
        font-size: 1.5rem;
    }

    ul.timeline-list li .timeline_content {
        width: 43%;
    }

    .timeline_content span {
        font-size: .9rem;
    }

    .timeline_content p {
        font-size: .5rem !important;
    }

    .parallax-container .react-parallax-content p {
        font-size: .7rem;
    }

    .parallax-container .react-parallax-content h2 {
        font-size: 1.5rem;
    }
}

.whoweare-main.privacy-terms .whoweare-section .online-platform {
    padding: 1rem 0 4rem 0 !important;

    h2 {
        margin-top: 48px;
        font-size: 24px !important;
        margin-bottom: 8px !important;
    }

    ul {
        color: #7c7c7c;
    }

    p {
        font-size: 16px !important;
    }
}

@media (max-width: 768px) {
    .whoweare-main.privacy-terms .whoweare-section .online-platform {
        h2 {
            font-size: 20px !important;
        }

        p {
            font-size: 14px !important;
        }
    }
}

.how-it-works-container {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px 100px;

    .heading-section {
        width: 50%;
        // padding-top: 260px;

        .how-it-works-text {
            text-align: left;
            color: #b1da50 !important;
            font-family: graphik-bold;
        }

        p {
            font-size: 1rem;
            color: $dark-grey;
            text-align: left;
        }
    }

    .how-it-works-image-container {
        width: 40%;
        position: relative;

        .hiw_text {
            position: absolute;
            top: 45%;
            left: 20%;
            width: 60%;
            text-align: center;
            font-family: graphik-semibold;
            color: #000;
        }

        .hiw_1 {
            width: 19%;
            height: 18%;
            border-radius: 50%;
            font-size: 12px;
            position: absolute;
            text-align: center;
            cursor: pointer;
            font-weight: bold;
            top: -2%;
            left: 25%;
            padding-top: 10px;
            
            img {
                width: 40%;
                object-fit: contain;
                margin-top: 19%;
            }
        }

        .hiw_2 {
            width: 19%;
            height: 18%;
            border-radius: 50%;
            font-size: 12px;
            position: absolute;
            text-align: center;
            cursor: pointer;
            font-weight: bold;
            top: 6%;
            right: 10%;
            padding-top: 10px;

            img {
                width: 40%;
                object-fit: contain;
                margin-top: 19%;
            }
        }

        .hiw_3 {
            width: 19%;
            height: 18%;
            border-radius: 50%;
            font-size: 12px;
            position: absolute;
            text-align: center;
            cursor: pointer;
            font-weight: bold;
            top: 40%;
            right: -8%;
            padding-top: 10px;

            img {
                width: 40%;
                object-fit: contain;
                margin-top: 19%;
            }
        }

        .hiw_4 {
            width: 19%;
            height: 18%;
            border-radius: 50%;
            font-size: 12px;
            position: absolute;
            text-align: center;
            cursor: pointer;
            font-weight: bold;
            bottom: 8%;
            right: 5%;
            padding-top: 10px;

            img {
                width: 40%;
                object-fit: contain;
                margin-top: 19%;
            }
        }

        .hiw_5 {
            width: 19%;
            height: 18%;
            border-radius: 50%;
            font-size: 12px;
            position: absolute;
            text-align: center;
            cursor: pointer;
            font-weight: bold;
            bottom: -7%;
            left: 35%;
            padding-top: 10px;

            img {
                width: 40%;
                object-fit: contain;
                margin-top: 19%;
            }
        }

        .hiw_6 {
            width: 19%;
            height: 18%;
            border-radius: 50%;
            font-size: 12px;
            position: absolute;
            text-align: center;
            cursor: pointer;
            font-weight: bold;
            top: 60%;
            left: -5%;
            padding-top: 10px;

            img {
                width: 40%;
                object-fit: contain;
                margin-top: 19%;
            }
        }

        .hiw_7 {
            width: 19%;
            height: 18%;
            border-radius: 50%;
            font-size: 12px;
            position: absolute;
            text-align: center;
            cursor: pointer;
            font-weight: bold;
            top: 25%;
            left: -30px;
            padding-top: 10px;

            img {
                width: 40%;
                object-fit: contain;
                margin-top: 19%;
            }
        }

        img {
            width: 100%;
            object-fit: contain;
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .how-it-works-container {
        .how-it-works-image-container {

            .hiw_1 {
                top: 1% !important;
                left: 25% !important;
                // width: 55px !important;
                height: 20% !important;
                font-size: 7px !important;
                padding-top: 0px !important;

                img {
                    margin-top: 25% !important;
                }
            }

            .hiw_2 {
                top: 9% !important;
                right: 14% !important;
                height: 20% !important;
                font-size: 7px !important;
                padding-top: 0px !important;

                img {
                    margin-top: 25% !important;
                }
            }

            .hiw_3 {
                top: 40% !important;
                right: 0% !important;
                height: 20% !important;
                font-size: 7px !important;
                padding-top: 0px !important;

                img {
                    margin-top: 25% !important;
                }
            }

            .hiw_4 {
                bottom: 3% !important;
                right: 12% !important;
                height: 20% !important;
                font-size: 7px !important;
                padding-top: 0px !important;

                img {
                    margin-top: 25% !important;
                }
            }

            .hiw_5 {
                bottom: -11% !important;
                left: 38% !important;
                height: 20% !important;
                font-size: 7px !important;
                padding-top: 0px !important;

                img {
                    margin-top: 25% !important;
                }
            }

            .hiw_6 {
                top: 71% !important;
                left: 7% !important;
                height: 20% !important;
                font-size: 7px !important;
                padding-top: 0px !important;

                img {
                    margin-top: 25% !important;
                }
            }

            .hiw_7 {
                top: 30% !important;
                left: 1% !important;
                height: 20% !important;
                font-size: 7px !important;
                padding-top: 0px !important;

                img {
                    margin-top: 25% !important;
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .every-party-main-container {
        .every-party-section {
            .holding-container {

                .producers,
                .investors,
                .consumers,
                .importers {
                    width: 75px !important;
                    height: 75px !important;
                    font-size: 7px !important;

                    img {
                        width: 20px !important;
                        height: 20px !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .every-party-main-container {
        display: inline-block !important;

        .every-party-section {
            width: 100% !important;
        }

        .every-party-content-container {
            width: 100% !important;
            padding-top: 50px !important;
            padding-left: 0px !important;
        }
    }

    .how-it-works-container {
        display: inline-block !important;
        padding: 0px 20px !important;

        .heading-section {
            width: 100% !important;
            padding-top: 0px !important;

            .how-it-works-text {
                text-align: center !important;
                color: #b1da50 !important;
            }

            p {
                text-align: center !important;
            }
        }

        .how-it-works-image-container {
            width: 100% !important;
            text-align: center;
            margin-top: 50px !important;
            padding: 0px 30px !important;
        }
    }

    .contact-us-container {
        display: inline-block !important;
        margin-top: 0px !important;

        .form-section {
            width: 100%;
            padding: 50px 20px;

            p {
                width: auto !important;
            }

            .form-field {
                width: 100% !important;
            }

            .form-button {
                width: 100% !important;
            }
        }

        .map-container {
            width: 100% !important;
            height: 400px !important;
        }
    }

    .invest-now {
        display: inline-block !important;
        padding: 20px !important;

        .invest-image-container {
            width: 100% !important;

            .interested-heading {
                font-size: 5vw;
            }
        }

        .invest-form-container {
            width: 100% !important;
            padding-left: 0px !important;

            .invest-form-new {
                padding: 20px !important;
                height: 591px !important;
                margin-top: 0px !important;
            }
        }
    }
}

@media screen and (max-width: 320px)  {
    .interested-heading {
        top: 140px !important;
        left: 31px !important;
    }

    .how-it-works-container {
        .how-it-works-image-container {
            .hiw_text {
                top: 43% !important;
                left: 24% !important;
                width: 150px !important;
                font-size: 8px !important;
            }
        }
    }
}

@media screen and (min-width: 321px) and (max-width: 375px) {
    .interested-heading {
        top: 150px !important;
        left: 39px !important;
    }

    .how-it-works-container {
        .how-it-works-image-container {
            .hiw_text {
                top: 43% !important;
                left: 24% !important;
                width: 180px !important;
                font-size: 8px !important;
            }
        }
    }
                    }

@media screen and (min-width: 376px) and (max-width: 425px)  {
    .interested-heading {
        top: 160px !important;
        left: 45px !important;
    }

    .how-it-works-container {
        .how-it-works-image-container {
            .hiw_text {
                top: 43% !important;
                left: 24% !important;
                width: 200px !important;
                font-size: 8px !important;
            }
        }
    }
                    }

@media screen and (min-width: 426px) and (max-width: 768px) {
    .interested-heading {
        top: 230px !important;
        left: 95px !important;
    }

    .how-it-works-container {
        .how-it-works-image-container {
            .hiw_text {
                top: 43% !important;
                left: 24% !important;
                width: 390px !important;
                font-size: 14px !important;
            }
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px)  {
    .interested-heading {
        top: 165px !important;
        left: 40px !important;
    }

    .how-it-works-container {
        .how-it-works-image-container {
            .hiw_text {
                top: 43% !important;
                left: 13% !important;
                width: 300px !important;
                font-size: 14px !important;
            }
        }
    }

    .invest-now {
        .invest-form-container {
            .invest-form-new {
                height: 568px !important;

                .form-field {
                    margin-bottom: 10px !important;
                }
            }
        }
    }

    .contact-us-container {
        margin-top: 0px !important;

        .form-section {
            p {
                width: 300px !important;
            }
        }
    }
}

@media screen and (min-width: 1025px) and (max-width: 1440px)  {
    .interested-heading {
        top: 200px !important;
        left: 70px !important;
    }

    .invest-now {
        .invest-form-container {
            .invest-form-new {
                height: 587px !important;
                width: 71%;
                .form-field {
                    margin-bottom: 10px !important;
                }
            }
        }
    }
}